<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="topSwiper">
      <el-carousel :interval="3000" :autoplay="true" :loop="true">
        <el-carousel-item v-for="(item, index) in imgs" :key="index">
          <img @click="goOtherWeb(item)" class="swiperImg" :src="item.fileUrl" :alt="item.title" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 企业动态 -->
    <div class="companyNews center">
      <div class="newsTitle">
        <div class="newsTitle-name">企业动态</div>
        <div class="newsTitle-img"></div>
      </div>
      <div class="newsContent">
        <div class="companyImg">
          <div class="companyImg-swiper">
            <swiper :options="swiperOption" ref="mySwiper" v-if="imgSwiper.length">
              <swiper-slide v-for="(item, index) in imgSwiper" :key="index">
                <a :href="item.linkAddress">
                  <img class="swiperImg" :src="item.url" :alt="item.name" />
                </a>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="companyNews-swiper">
          <div class="newsList">
            <div
              class="newsList-items"
              v-for="(item, index) in newsList"
              :key="index"
              @click="detailsInfo(item.id)"
            >
              <div class="items-icon iconfont icon-danseyuandian"></div>
              <div class="items-text">{{ item.title }}</div>
              <div class="items-time">{{ item.time }}</div>
            </div>
          </div>
          <div class="moreNews">
            <a href="#/enterpriseNews">
              查看更多
              <i class="iconfont icon-jiantou"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="bg-case">
      <div class="case center">
        <div class="caseTitle">
          <div class="newsTitle-name">客户案例</div>
          <div class="caseTitle-text">瑞懿专业的服务团队为您提供售前购买咨询、解决方案推荐、配置推荐等1v1服务，助您数据无忧</div>
        </div>
        <div class="caseBank">
          <div class="bankName"></div>
          <div class="bankItems">
            <div :class="item.style+' bankItems-item2'" v-for="(item, index) in bankList" :key="index">
              <img :src="item.url"  alt />
            </div>
          </div>
        </div>
        <div class="caseBank">
          <div class="companyName"></div>
          <div class="bankItems">
            <div class="bankItems-item" v-for="(item, index) in caseList" :key="index">
              <img class="bankItems-img" :src="item.url" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { sysNewsList } from "../../api/enterpriseNews.js";
import { topSwiperList } from "../../api/home.js";
import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      start: false,
      imgs: [],
      imgSwiper: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        effect: "fade"
      },
      bankList: [
        {
          url: require("../../assets/image/图层 1.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 2.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 3.png"),
          style:"bankItems-item2-img1"
        },

        {
          url: require("../../assets/image/图层 4.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 5.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 6.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 7.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 8.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 9.png"),
          style:"bankItems-item2-img1"
        },

        {
          url: require("../../assets/image/图层 10.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 11.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 12.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 13.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 14.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 16.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 17.png"),
          style:"bankItems-item2-img1"
        },

        {
          url: require("../../assets/image/图层 18.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 19.png"),
          style:"bankItems-item2-img2"
        },
        {
          url: require("../../assets/image/图层 20.png"),
          style:"bankItems-item2-img1"
        },
        {
          url: require("../../assets/image/图层 21.png"),
          style:"bankItems-item2-img2"
        }
      ],
      caseList: [
        {
          url: require("../../assets/image/绿城.png")
        },
        {
          url: require("../../assets/image/万达.png")
        },
        {
          url: require("../../assets/image/光大.png")
        },
        {
          url: require("../../assets/image/渤海.png")
        },
        {
          url: require("../../assets/image/工商.png")
        },
        {
          url: require("../../assets/image/浙商.png")
        }
      ],
      newsList: []
    };
  },
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    // 企业动态数据
    getInfoList() {
      let params = {
        pageNum: 1,
        pageSize: 7
      };
      const arr = [];
      const imgs = [];
      sysNewsList(params).then(res => {
        if (res.data.code == 200) {
          console.log(res.data.data.list);
          res.data.data.list.map(item => {
            arr.push({
              id: item.id,
              title: item.title,
              time: item.creationTime.slice(0, 10)
            });
            imgs.push({
              url: item.filename
            });
          });
          this.newsList = arr;
          this.imgSwiper = imgs;
        }
      });
    },
    //顶部轮播图片
    getTopSwiperList() {
      const param = {
        isShelf: 1,
        pageNum: 1,
        pageSize: 99
      };

      topSwiperList(param)
        .then(res => {
          console.log(res.data.data.list);
          this.imgs = res.data.data.list;
        })
        .catch(err => {
          console.log(err);
        });
    },
    detailsInfo(id) {
      console.log(id);
      this.$router.push({
        path: "/newsDetail",
        query: {
          row: id
        }
      });
    },
    //顶部轮播链接跳转
    goOtherWeb(data) {
      if (data.isJump == 1 && data.jumpUrl) {
        window.open(data.jumpUrl);
      }
    }
  },
  mounted() {
    this.getInfoList();
    this.getTopSwiperList();
  }
};
</script>

<style scoped>
@import url("../../assets/swiper/swiper.min.css");

.home {
  width: 100%;
}

.swiperImg {
  width: 100%;
  height: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.companyNews {
  height: 510px;
}
.newsTitle {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
.newsTitle-name {
  font-size: 30px;
  font-weight: bold;
  color: #00041a;
  margin-bottom: 8px;
}
.newsTitle-img {
  height: 12px;
  width: 184px;
  margin: 0 auto;
  background-image: url("../../assets/image/企业动态.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.newsContent {
  display: flex;
  justify-content: space-between;
}
.companyImg {
  width: 501px;
  height: 295px;
  margin-top: 18px;
  border: 8px solid #0070ff;
  position: relative;
}
.companyImg-swiper {
  width: 501px;
  height: 295px;
  position: absolute;
  top: -24px;
  right: -24px;
  border: 1px solid rgb(212, 212, 212);
  box-shadow: 0px 0px 30px rgb(175, 175, 175);
}
.companyImg-swiper img {
  width: 100%;
  height: 100%;
}
.companyNews-swiper {
  width: 640px;
}
.newsList {
  width: 100%;
  height: 338px;
  overflow: hidden;
}
.moreNews {
  font-size: 14px;
  color: #98a0ac;
  cursor: pointer;
  margin-top: 25px;
}
.moreNews a {
  color: #98a0ac;
}
.moreNews:hover a {
  color: #0070ff;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
}

.companyNews-icon {
  width: 4%;
}
.companyNews-content {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.companyNews-time {
  text-align: right;
  width: 15%;
  font-size: 14px;
}
.caseTitle {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.bg-case {
  width: 100%;
  background-image: url("../../assets/image/客户案例背景.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.case {
  /* height: 892px; */
  height: 1250px;
  padding: 40px 0px 20px 0px;
}
.caseBank {
  margin-bottom: 40px;
}
.caseTitle-text {
  font-size: 14px;
  font-weight: 400;
}
.bankName {
  width: 191px;
  height: 79px;
  background-image: url("../../assets/image/合作银行.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bankItems {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  box-shadow: 5px 5px 50px #e0dfdf;
}
.bankItems-item {
  width: 33.3%;
  height: 120px;
  padding: 22px 90px;
  display: flex;
}
.bankItems-item img {
  width: 100%;
  height: 100%;
}
.bankItems-item:nth-child(2n + 1) {
  background-color: #f2f5f9;
}
.bankItems-item:nth-child(2n) {
  background-color: #ffff;
}
.bankItems-item2 {
  height: 120px;
  display: flex;
  width: 25%;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.bankItems-item2-img1 {
  background-color: #f2f5f9;
}
.bankItems-item2-img2 {
  background-color: #ffff;
}
.companyName {
  width: 347px;
  height: 79px;
  background-image: url("../../assets/image/合作房企.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.newsList-items {
  display: flex;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #e4e6e9;
  cursor: pointer;
}
.items-icon {
  width: 3%;
  color: #e4e6e9;
  margin-right: 10px;
}
.items-text {
  width: 80%;
  font-size: 14px;
  color: #00041a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}
.items-time {
  width: 15%;
  font-size: 14px;
  color: #98a0ac;
}
.newsList-items:hover .items-icon {
  color: #0070ff !important;
}
.newsList-items:hover .items-text {
  color: #0070ff !important;
}
.newsList-items:hover .items-time {
  color: #0070ff !important;
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 30px 15px;
}
::v-deep .el-carousel__container {
  height: 500px;
}
::v-deep .el-carousel__button {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
::v-deep .el-carousel__indicator.is-active button {
  opacity: 1;
  width: 50px;
  border-radius: 10px;
  height: 10px;
}
::v-deep .el-carousel__arrow--left {
  left: 15%;
}
::v-deep .el-carousel__arrow--right {
  right: 15%;
}
::v-deep .el-carousel__arrow {
  border: 1px solid #ccc;
  overflow: hidden;
}
::v-deep .el-carousel__arrow i {
  width: 36px;
  height: 36px;
  line-height: 33px;
  border-radius: 50%;
  border: 1px solid rgba(28, 91, 158, 0);
}
::v-deep .el-icon-arrow-left:before {
  font-weight: bold;
}
::v-deep .el-icon-arrow-right:before {
  font-weight: bold;
}
::v-deep .el-carousel__arrow i:hover {
  border: 1px solid rgba(0, 122, 255, 0.8);
  background-color: rgba(0, 122, 255, 0.8);
}
</style>
