import axios from 'axios';

import API from './index.js'


//轮播列表
export const topSwiperList = (param) => {
    return axios.request({
        method: "post",
        url: API.CORE_API + '/sysLunbo/list',
        data: param
    })
};